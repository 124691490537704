<template>
  <div class="box-dialog">
    <el-dialog
      width="600px"
      title="编辑规格表示示例"
      :visible.sync="selfShow"
      :close-on-click-modal="false"
    >
      <el-form ref="refForm" label-width="120px">
        <el-form-item label="选择规格:" class="contral-box">
          <el-button
            v-for="(item, index) in list"
            :key="index"
            :type="demoNameList.includes(item.name) ? 'primary' : 'default'"
            size="mini"
            @click="demoToggle(item)"
          >
            {{ item.name }}
          </el-button>
          <el-button type="danger" size="mini" @click="demoClear"> 清空 </el-button>
        </el-form-item>
        <template v-if="selfActiveList.length">
          <el-form-item label="已选规格:" class="contral-box">
            <el-link
              v-for="(item, index) in demoNameList"
              :key="index"
              type="success"
              size="mini"
              :underline="false"
              disabled
            >
              {{ item }}
            </el-link>
          </el-form-item>
          <el-form-item label="规格表示示例:">
            <el-input v-model="demoStr" size="mini" disabled />
          </el-form-item>
          <el-form-item label="" class="p-y-5">
            <vxe-grid v-bind="tableOptions" :data="selfActiveList">
              <template #default_val="{ row }">
                <el-input
                  type="number"
                  size="mini"
                  placeholder="必填项"
                  clearable
                  v-model="row.val"
                />
              </template>
            </vxe-grid>
            <div class="c-gray-c">(温馨提示：请对应前端展示规格顺序依次选择规格)</div>
          </el-form-item>
        </template>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="popupCancel">取消</el-button>
        <el-button size="small" type="primary" @click="popupConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    list: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfList: [],
      selfActiveList: [],
      tableOptions: {
        size: 'mini',
        border: true,
        'highlight-hover-row': true,
        columns: [
          { title: '规格名称', field: 'name', width: 200 },
          { title: '规格数值', slots: { default: 'default_val' } }
        ]
      }
    }
  },
  computed: {
    // 示例-名称列表
    demoNameList() {
      let result = this.selfActiveList.map((item) => item.name)
      return result
    },
    // 示例-值列表
    demoValList() {
      let result = this.selfActiveList.map((item) => item.val)
      return result
    },
    // 示例-字符串
    demoStr() {
      let strName = this.demoNameList.join('*')
      let strVal = this.demoValList.join('*')
      let result = ''
      if (strName) result = `${strName} = ${strVal}`
      return result
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    this.selfList = this.$util.deepCopy(this.list)
    this.selfActiveList = this.selfList.filter((item) => item.order_id)
  },
  methods: {
    // 示例-切换
    demoToggle(data) {
      let idx = this.demoNameList.indexOf(data.name)
      if (idx > -1) {
        this.selfActiveList.splice(idx, 1)
      } else {
        this.selfActiveList.push({
          ...data
        })
      }
    },
    // 示例-清空
    demoClear() {
      this.selfActiveList.splice(0, this.selfActiveList.length)
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      if (!this.demoNameList.length) {
        this.$message.error('请选择规格')
        return
      }
      if (this.demoValList.some((item) => !item)) {
        this.$message.error('请输入规格数值')
        return
      }
      this.selfList.forEach((item) => {
        let idx = this.demoNameList.indexOf(item.name)
        if (idx > -1) {
          item.order_id = idx + 1
          item.val = this.selfActiveList[idx].val
        } else {
          item.order_id = ''
        }
      })
      this.$emit('success', this.selfList)
      this.popupCancel()
    }
  }
}
</script>

<style lang="less" scoped>
.box-dialog {
  .contral-box {
    /deep/ .el-button {
      min-width: 38px;
    }

    /deep/ .el-link {
      vertical-align: top;
      & ~ .el-link {
        margin-left: 5px;
      }
    }
  }
}
</style>
