<template>
  <div class="module-unit">
    <div class="module-first">
      <div>
        <el-button type="primary" size="mini" plain @click="importBatch">批量引用</el-button>
        <el-button type="success" size="mini" plain @click="groupUnitAdd">新增单位</el-button>
      </div>
      <el-button type="success" size="mini" @click="listSave">保存单位</el-button>
    </div>
    <div v-for="(item, index) in selfList" :key="index" class="table-unit-group">
      <vxe-grid v-bind="tableOptions" :data="item.huan_suan_lv_list">
        <template #header_gs>
          <div class="flex flex-y-center flex-x-between">
            <div class="flex flex-y-center">
              <span class="unit-tag">{{ item.unit_name }}</span>
              <span>计算公式：</span>
              <span>
                {{ item.gong_shi ? item.gong_shi.map((item) => item.name).join('') : '无' }}
              </span>
            </div>
            <div>
              <el-link type="primary" @click="groupUnitEdit(index)">编辑</el-link>
              <el-link type="danger" class="m-l-5" @click="groupUnitDelete(index)">删除</el-link>
            </div>
          </div>
        </template>
        <template #default_unit_name="{ row }">
          <el-input size="mini" clearable v-model="row.unit_name" @blur="inputBlur(row)" />
        </template>
        <template #default_huan_suan_lv="{ row }">
          <el-input
            type="number"
            size="mini"
            clearable
            :disabled="row.is_main"
            v-model="row.huan_suan_lv"
            @blur="inputBlur(row)"
          />
        </template>
        <template #default_is_main="{ row }">
          <span v-if="row.is_main" class="c-green">主计量</span>
          <!-- <el-link
            v-if="!row.is_main"
            class="set-default"
            type="primary"
            :underline="false"
            @click="itemUnitSetDefault(index, rowIndex)"
          >
            设为主计量
          </el-link> -->
        </template>
        <template #default_operate="{ rowIndex }">
          <span class="iconfont icon-add" title="增行" @click="itemUnitAdd(index, rowIndex)" />
          <span
            class="iconfont icon-delete"
            title="删行"
            @click="itemUnitDelete(index, rowIndex)"
          />
        </template>
      </vxe-grid>
    </div>
    <PopupImport
      v-if="isShowPopupImport"
      :show.sync="isShowPopupImport"
      :obj="obj"
      @success="importConfirm"
    />
    <PopupUnitGroupDo
      v-if="isShowPopupUnitGroupDo"
      :show.sync="isShowPopupUnitGroupDo"
      :guigeList="guigeList"
      :list="selfList"
      :data.sync="unitGroupObj"
      @success="unitGroupUpdate"
    />
  </div>
</template>

<script>
import PopupImport from './popup-import-unit'
import PopupUnitGroupDo from './popup-unit-group-do'
export default {
  components: {
    PopupImport,
    PopupUnitGroupDo
  },
  props: {
    list: {
      type: Array,
      required: true
    },
    guigeList: {
      type: Array,
      required: true
    },
    obj: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selfList: [],
      tableOptions: {
        size: 'mini',
        border: true,
        'highlight-hover-row': true,
        columns: [
          {
            title: '计算公式',
            slots: { header: 'header_gs' },
            children: [
              {
                title: '单位',
                width: 201,
                slots: { default: 'default_unit_name' }
              },
              {
                title: '换算率',
                width: 201,
                titleSuffix: {
                  content: '辅计量对比主计量的换算比例',
                  icon: 'vxe-icon-question-circle-fill'
                },
                slots: { default: 'default_huan_suan_lv' }
              },
              { title: 'id', field: 'id', width: 201 },
              {
                title: '主计量单位',
                width: 101,
                slots: { default: 'default_is_main' }
              },
              { title: '操作', align: 'right', slots: { default: 'default_operate' } }
            ]
          }
        ]
      },
      isShowPopupImport: false,
      isShowPopupUnitGroupDo: false,
      unitGroupIdx: -1,
      unitGroupObj: {}
    }
  },
  watch: {
    list: {
      handler(val) {
        if (JSON.stringify(this.selfList) !== JSON.stringify(val)) {
          this.selfList = this.$util.deepCopy(val)
        }
      },
      deep: true,
      immediate: true
    },
    selfList: {
      handler(val) {
        if (JSON.stringify(this.list) !== JSON.stringify(val)) {
          this.$emit('update:list', this.$util.deepCopy(val))
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 组-单位-新增
    groupUnitAdd() {
      this.unitGroupIdx = -1
      this.unitGroupObj = {
        column_data_id: this.obj.id,
        unit_name: '',
        unit_val: '',
        gong_shi: [],
        huan_suan_lv_list: []
      }
      this.isShowPopupUnitGroupDo = true
    },
    // 组-单位-编辑
    groupUnitEdit(index) {
      this.unitGroupIdx = index
      this.unitGroupObj = this.$util.deepCopy(this.selfList[index])
      this.isShowPopupUnitGroupDo = true
    },
    // 组-单位-删除
    async groupUnitDelete(index) {
      let { id } = this.selfList[index]
      let isFail = false
      if (id) {
        await this.$api({
          method: 'get',
          url: '/admin/gcvip/columnDataJiSuanGongShi/delete',
          params: {
            id
          }
        })
          .then((res) => {
            if (res.data.state === 'ok') {
              this.$message.success('删除成功')
            } else {
              this.$message.error(res.data.msg)
              isFail = true
            }
          })
          .catch((err) => {
            this.$message.error(err.data.msg)
            isFail = true
          })
      }
      if (isFail) return
      this.selfList.splice(index, 1)
    },
    // 组-单位-更新
    unitGroupUpdate(data) {
      if (this.unitGroupIdx >= 0) {
        this.selfList.splice(this.unitGroupIdx, 1, data)
      } else {
        this.selfList.push(data)
      }
      this.listSave()
    },
    // 项-单位-新增
    itemUnitAdd(index, rowIndex) {
      this.selfList[index].huan_suan_lv_list.splice(rowIndex + 1, 0, {
        unit_name: '',
        huan_suan_lv: '',
        is_main: false
      })
    },
    // 项-单位-删除
    itemUnitDelete(index, rowIndex) {
      let { id, is_main } = this.selfList[index].huan_suan_lv_list[rowIndex]
      let isFail = false
      if (id) {
        if (is_main) {
          this.$message.error('主计量单位不能删除')
          return
        }
        this.$api({
          method: 'get',
          url: '/admin/gcvip/columnDataJiSuanGongShi/deleteByHuanSuan',
          params: {
            id
          }
        })
          .then((res) => {
            if (res.data.state === 'ok') {
              this.$message.success('删除成功')
            } else {
              this.$message.error(res.data.msg)
              isFail = true
            }
          })
          .catch((err) => {
            this.$message.error(err.data.msg)
            isFail = true
          })
      }
      if (isFail) return
      if (this.selfList[index].huan_suan_lv_list.length > 1) {
        this.selfList[index].huan_suan_lv_list.splice(rowIndex, 1)
      } else {
        this.selfList[index].huan_suan_lv_list[rowIndex].unit_name = ''
        this.selfList[index].huan_suan_lv_list[rowIndex].huan_suan_lv = ''
        this.selfList[index].huan_suan_lv_list[rowIndex].is_main = false
      }
    },
    // 项-单位-设置主计量
    // itemUnitSetDefault(idx, rowIdx) {
    //   let oldVal = this.selfList[idx].huan_suan_lv_list[rowIdx].huan_suan_lv
    //   if (!Number(oldVal)) {
    //     this.$message.error('设为主计量换算率不能为空或0')
    //     return
    //   }
    //   this.selfList[idx].huan_suan_lv_list.forEach((item, index) => {
    //     if (index === rowIdx) {
    //       item.is_main = true
    //     } else {
    //       item.is_main = false
    //     }
    //     item.huan_suan_lv = this.$XEUtils.divide(item.huan_suan_lv, oldVal)
    //   })
    // },
    // 引用-批量
    importBatch() {
      this.isShowPopupImport = true
    },
    // 引用-确认
    importConfirm(importList) {
      let result = []
      importList.forEach((importItem) => {
        let isRepeat = this.selfList.some((selfItem) => {
          return selfItem.unit_name === importItem.unit_name
        })
        if (!isRepeat) {
          let huan_suan_lv_list = importItem.huan_suan_lv_list.map((huanSuanLvItem) => {
            return {
              column_data_id: this.obj.id,
              unit_name: huanSuanLvItem.unit_name,
              huan_suan_lv: huanSuanLvItem.huan_suan_lv,
              is_main: huanSuanLvItem.is_main
            }
          })
          result.push({
            column_data_id: this.obj.id,
            unit_name: importItem.unit_name,
            unit_val: importItem.unit_val,
            gong_shi: [...importItem.gong_shi],
            huan_suan_lv_list
          })
        }
      })
      if (result.length) {
        let effectList = this.selfList.filter((item) => {
          return item.unit_name
        })
        this.selfList = [...effectList, ...result]
      }
      this.listSave()
    },
    // 输入框-失焦
    inputBlur(row) {
      if (row.unit_name && row.huan_suan_lv) this.listSave()
    },
    // 列表-保存
    listSave() {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/columnDataJiSuanGongShi/saveOrUpdate',
        data: {
          list: this.selfList
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success('保存成功')
          this.$emit('success')
        } else {
          this.$message.error(res.data.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.module-unit {
  .module-first {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 28px;
  }
  .table-unit-group {
    margin-top: 5px;
    .unit-tag {
      color: @colorWhite;
      padding: 0 2px;
      margin-right: 5px;
      border-radius: 2px;
      background: @colorBlue;
    }
    .set-default {
      display: none;
    }
    /deep/ .row--hover {
      .set-default {
        display: block;
      }
    }
  }
}
</style>
