<template>
  <div class="module-caizhi">
    <div class="module-first">
      <el-button type="primary" size="mini" plain @click="importBatch">批量引用</el-button>
      <el-button type="success" size="mini" @click="listSave">保存材质</el-button>
    </div>
    <vxe-grid v-bind="tableOptions" :data="selfList">
      <template #default_cai_zhi="{ row }">
        <el-input size="mini" clearable v-model="row.cai_zhi" @blur="inputBlur(row)" />
      </template>
      <template #default_val="{ row }">
        <el-input type="number" size="mini" clearable v-model="row.val" @blur="inputBlur(row)" />
      </template>
      <template #default_operate="{ rowIndex }">
        <span class="iconfont icon-add" title="增行" @click="itemAdd(rowIndex)" />
        <span class="iconfont icon-delete" title="删行" @click="itemDelete(rowIndex)" />
      </template>
    </vxe-grid>
    <PopupImport
      v-if="isShowPopupImport"
      :show.sync="isShowPopupImport"
      :obj="obj"
      @success="importConfirm"
    />
  </div>
</template>

<script>
import PopupImport from './popup-import-caizhi'
export default {
  components: {
    PopupImport
  },
  props: {
    list: {
      type: Array,
      required: true
    },
    obj: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selfList: [
        {
          cai_zhi: '',
          val: ''
        }
      ],
      tableOptions: {
        size: 'mini',
        border: true,
        'highlight-hover-row': true,
        columns: [
          {
            title: '材质名称',
            width: 201,
            slots: { default: 'default_cai_zhi' }
          },
          { title: '密度', width: 201, slots: { default: 'default_val' } },
          { title: 'id', field: 'id', width: 201 },
          { title: '操作', align: 'right', slots: { default: 'default_operate' } }
        ]
      },
      isShowPopupImport: false
    }
  },
  watch: {
    list: {
      handler(val) {
        if (val.length) {
          if (JSON.stringify(this.selfList) !== JSON.stringify(val)) {
            this.selfList = this.$util.deepCopy(val)
          }
        } else {
          this.selfList = [
            {
              cai_zhi: '',
              val: ''
            }
          ]
        }
      },
      deep: true,
      immediate: true
    },
    selfList: {
      handler(val) {
        if (JSON.stringify(this.list) !== JSON.stringify(val)) {
          this.$emit('update:list', this.$util.deepCopy(val))
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 项-新增
    itemAdd(index) {
      this.selfList.splice(index + 1, 0, {
        cai_zhi: '',
        val: ''
      })
    },
    // 项-删除
    async itemDelete(index) {
      let id = this.selfList[index].id
      let isFail = false
      if (id) {
        await this.$api({
          method: 'get',
          url: '/admin/gcvip/midu/delete',
          params: {
            id
          }
        })
          .then((res) => {
            if (res.data.state === 'ok') {
              this.$message.success('删除成功')
            } else {
              this.$message.error(res.data.msg)
              isFail = true
            }
          })
          .catch((err) => {
            this.$message.error(err.data.msg)
            isFail = true
          })
      }
      if (isFail) return
      if (this.selfList.length > 1) {
        this.selfList.splice(index, 1)
      } else {
        this.selfList[index].cai_zhi = ''
        this.selfList[index].val = ''
      }
    },
    // 引用-批量
    importBatch() {
      this.isShowPopupImport = true
    },
    // 引用-确认
    importConfirm(importList) {
      let result = []
      importList.forEach((importItem) => {
        let isRepeat = this.selfList.some((selfItem) => {
          return selfItem.cai_zhi === importItem.cai_zhi
        })
        if (!isRepeat) {
          result.push({
            cai_zhi: importItem.cai_zhi,
            val: importItem.val
          })
        }
      })
      if (result.length) {
        let effectList = this.selfList.filter((item) => {
          return item.cai_zhi && item.val
        })
        this.selfList = [...effectList, ...result]
      }
      this.listSave()
    },
    // 输入框-失焦
    inputBlur(row) {
      if (row.cai_zhi && row.val) this.listSave()
    },
    // 列表-保存
    listSave() {
      let effectList = this.selfList.filter((item) => {
        return item.cai_zhi && item.val
      })
      let pushList = effectList.map((item) => {
        return {
          ...item,
          column_data_id: this.obj.id
        }
      })
      this.$api({
        method: 'post',
        url: '/admin/gcvip/midu/saveOrUpdateByList',
        data: {
          list: pushList
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success('保存成功')
          this.$emit('success')
        } else {
          this.$message.error(res.data.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.module-caizhi {
  .module-first {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 28px;
    margin-bottom: 5px;
  }
}
</style>
